import React from "react";
import styled from "styled-components/macro";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";

const SocialIconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 24px;
  }
  a {
    margin: 0 5px;
    color: #414141;
    :hover {
      color: #d6d6d6;
      transition: all 0.3s ease;
    }
  }
`;

function SocialIcons() {
  return (
    <SocialIconsWrapper>
      <a
        rel="noreferrer"
        target="_blank"
        href="https://www.facebook.com/profile.php?id=100087196167320"
      >
        <AiFillFacebook />
      </a>
      <a
        rel="noreferrer"
        target="_b lank"
        href="https://www.instagram.com/ddc.digitaldentalcare/?hl=en"
      >
        <AiFillInstagram />
      </a>
    </SocialIconsWrapper>
  );
}

export default SocialIcons;
