import React from "react";
import styled from "styled-components/macro";
import { SocialIcons } from "../../../components";

const ComingSoonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const ComingSoonInner = styled.div`
  width: 400px;
  padding: 20px;
  text-align: center;
  h1 {
    margin-bottom: 20px;
    color: #323232;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  p {
    font-size: 28px;
    color: #3b3b3b;
    margin: 10px 0 20px 0;
  }
`;

function ComingSoon() {
  return (
    <ComingSoonWrapper>
      <ComingSoonInner>
        <h1>Coming Soon</h1>
        <img src={require("../../../images/DDC_logo.svg").default} alt="logo" />
        <p>Digital Dental Care</p>
        <SocialIcons />
      </ComingSoonInner>
    </ComingSoonWrapper>
  );
}

export default ComingSoon;
